<template>
  <div>
    <h1 class="py-6">Adatbázisjavítás</h1>

    <div v-if="missing_tables.length">
      <div class="text-h6">Hiányzó táblák</div>
      <v-list dense>
        <v-list-item v-for="table in missing_tables" :key="'missing' + table">
          <v-list-item-content>
            {{ table }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Típus
            </th>
            <th class="text-left">
              Referencia
            </th>
            <th class="text-left">
              Eltérés
            </th>
          </tr>
        </thead>
          <tbody>
            <tr v-for="difference in schema_differences" :key="'diffrow-'+difference.current.field">
                <td>{{ difference.type }}</td>
                <td>{{ difference.table_name }}.{{ difference.reference.field }}</td>
                <td>{{ difference.table_name }}.{{ difference.current.field }}</td>
            </tr>
          </tbody>
      </template>
    </v-simple-table>


    <!-- <div v-if="schema_differences">
      <div class="text-h6">Tábla különbségek</div>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
          v-for="difference in schema_differences"
          :key="difference.table_name"
        >

          <v-card elevation="1">
            <v-card-title>{{ difference.table_name }}</v-card-title>

            <v-list dense>
              <v-list-item v-if="difference.fields" class="font-weight-bold">Mezők</v-list-item>
              <v-list-item
                v-for="(item, index) in difference.fields"
                :key="difference.table_name + index"
              >
                <v-list-item-content>
                  {{ item.current.field }}
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.reference.field }}
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list dense>
              <v-list-item v-if="difference.indexes" class="font-weight-bold">Indexek</v-list-item>
              <v-list-item
                v-for="(item, index) in difference.indexes"
                :key="difference.table_name + index"
              >
                <v-list-item-content>
                  {{ item.current.column_name }}
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ item.reference.column_name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      missing_tables: [],
      field_differences: [],
      index_differences: [],
    };
  },

  mounted() {
    this.fetchDatabaseDifferences();
  },

  methods: {
    async fetchDatabaseDifferences() {
      const response = await this.$http.get('superadmin/db/check-consistency');
      this.field_differences = response.field_differences;
      this.index_differences = response.index_differences;
      this.missing_tables = response.missing_tables;
    },
  },
};
</script>
