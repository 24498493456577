import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Adatbázisjavítás")]), _vm.missing_tables.length ? _c('div', [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v("Hiányzó táblák")]), _c(VList, {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.missing_tables, function (table) {
    return _c(VListItem, {
      key: 'missing' + table
    }, [_c(VListItemContent, [_vm._v(" " + _vm._s(table) + " ")])], 1);
  }), 1)], 1) : _vm._e(), _c(VSimpleTable, {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Típus ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Referencia ")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v(" Eltérés ")])])]), _c('tbody', _vm._l(_vm.schema_differences, function (difference) {
          return _c('tr', {
            key: 'diffrow-' + difference.current.field
          }, [_c('td', [_vm._v(_vm._s(difference.type))]), _c('td', [_vm._v(_vm._s(difference.table_name) + "." + _vm._s(difference.reference.field))]), _c('td', [_vm._v(_vm._s(difference.table_name) + "." + _vm._s(difference.current.field))])]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }